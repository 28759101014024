import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="digitalocean"
    />
    <Layout.Hero
      type="primary"
      title={(<>Flexible <span className="text-primary">DigitalOcean backups</span> without the constraints.</>)}
      subTitle={(
        <>Create daily and hourly DigitalOcean backups.<br/>
          Store them where you want, and recover them with ease at a fraction of the cost.
        </>)}
      checkMarks={['Droplet backup', 'Spaces backup', 'Managed Database backup', 'Flexible scheduling & policies', 'Cost optimisation']}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename="brands/icons/digitalocean-ico.png" alt="DigitalOcean Droplet backup" className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your DigitalOcean Backup" urlAtrr={{ sb_source: "website", sb_term: "do-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-hero-dashboard.png" alt="SimpleBackups MySQL backup dashboard: all your MySQL backups in one place"/>
      )}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section color="alt">
      <Ui.Container className="mt-24">
        <Ui.Row>
          <div><Ui.Quote author="- Said by too many developers">Does anyone know how to back up DigitalOcean daily?</Ui.Quote></div>

          <div className="grid grid-cols-3 md:grid-cols-4 mt-24 md:max-w-5xl m-auto">
            <div className="flex flex-col justify-center text-center">
              <Ui.Image filename="server-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <p className="mt-3 font-bold">Server & Files<i className="fal fa-arrow-right text-sm ml-2"></i></p>
            </div>
            <div className="flex flex-col justify-center text-center">
              <Ui.Image filename="db-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <p className="mt-3 font-bold">Managed Database<i className="fal fa-arrow-right text-sm ml-2"></i></p>
            </div>
            <div className="flex flex-col justify-center text-center">
              <Ui.Image filename="snapshot-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <p className="mt-3 font-bold">Droplet & Volume<i className="fal fa-arrow-right text-sm ml-2"></i></p>
            </div>
            <div className="flex flex-col justify-center text-center">
              <Ui.Image filename="storage-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <p className="mt-3 font-bold">Storage<i className="fal fa-arrow-right text-sm ml-2"></i></p>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>


    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: 'Built for DigitalOcean',
                title: "Easily connect your DigitalOcean account",
                description: (
                  <>
                    <p>
                      Connect your DigitalOcean account easily, using OAuth authentication.<br/>
                      Select the resources you want to back up and you're done!
                    </p>
                  </>
                ),
                image: (<div className="bg-white p-6 rounded-2xl"><Ui.Image
                  filename='simplebackups-digitalocean-easy-setup.png'
                  alt="digitalocean quick backup setup"
                /></div>)
              },
              {
                preTitle: "DigitalOcean backups got better",
                title: (<>Flexible & unrestricted scheduling for DigitalOcean</>),
                description: (
                  <>
                    <p>
                      Weekly DigitalOcean backups just don’t cut it.<br/>
                      Unlock flexible scheduling & retention for your DigitalOcean backups.<br/>

                      <Ui.FeatureChecks items={[
                        'Full control of when your backup will run', 'Unrestricted retention', 'Unlimited backup intervals (down to every minute)']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename="simplebackups-app-scheduling.png"
                  alt="digitalocean hourly backups"
                  style={{maxWidth: 400}}
                />)
              },
              {
                preTitle: "All your data secured",
                title: (<>Droplet, Volume, Spaces and database backups</>),
                description: (
                  <>
                    <p>
                      Fully flexible backup for all DigitalOcean products.<br/>
                      If it’s hosted on DigitalOcean, SimpleBackups can back it up:
                      <Ui.FeatureChecks items={[
                        'Automated Droplet Snapshots', 'Automated Volume Snapshots', 'Automated Spaces backup', 'Managed Database']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-digitalocean-configure.png'
                  alt="digitalocean backup databases"
                  style={{maxWidth: 450}}
                />)
              },
            ]}></Ui.FeatureRows>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              Content_FeatureTrust({subject: 'DigitalOcean'}),
            ]}></Ui.FeatureRows>

          <div className="mt-16">
            <Ui.Typography tag="h3" className="text-center">Your backups under good control:</Ui.Typography>
            <Sections.FeatureSlider tags={["snapshot"]} />

            <div className="mt-12 text-center">
              <Ui.Typography tag="h3" className="text-center">DigitalOcean backup sometimes just don't cut it!</Ui.Typography>
              <div className="mt-6 bg-white rounded-2xl p-8 md:max-w-4xl m-auto ">
                <table className="table-fixed w-full border-gray-50">
                <thead className="pb-3">
                <tr>
                  <th className="w-1/3"></th>
                  <th className="w-1/3 p-4 border-b border-l">
                    <div className="flex justify-center"><Ui.Image filename="digitalocean-backups-logo.png"
                                                                alt="digitalocean backups"
                                                                style={{width: 120, maxWidth: 120}}/></div>
                  </th>
                  <th className="w-1/3 p-4 border-b border-l">
                    <div className="flex justify-center"><Ui.Image filename="simplebackups-logo.png"
                                                                alt="simplebackups backups"
                                                                style={{width: 120, maxWidth: 120}}/></div>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody className="text-base">
                <tr>
                  <td className="font-medium text-left p-3 border-b">Droplet Backup</td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Volume Backup</td>
                  <td className="border-b border-l"><i className="fa fa-times text-red"/></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Spaces Replication</td>
                  <td className="border-b border-l"><i className="fa fa-times text-red"/></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Website / File Backup</td>
                  <td className="border-b border-l"><i className="fa fa-times text-red"/></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Database (MySQL, MongoDB, PostgreSQL) Backup</td>
                  <td className="border-b border-l"><i className="fa fa-times text-red"/></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Incremental MySQL Backup</td>
                  <td className="border-b border-l"><i className="fa fa-times text-red"></i></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Centralized Dashboard</td>
                  <td className="border-b border-l"><i className="fa fa-times text-red"></i></td>
                  <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Backup Schedule</td>
                  <td className="border-b border-l">Weekly</td>
                  <td className="border-b border-l">Down to 1 minute (flexible)</td>
                </tr>
                <tr>
                  <td className="font-medium text-left p-3 border-b">Cost</td>
                  <td className="border-b border-l">Min 20% of server cost</td>
                  <td className="border-b border-l">Flat fee (starts for free)</td>
                </tr>
                </tbody>

              </table>
              </div>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>



    <Sections.SectionLearn
      title={<>Wanna learn more about DigitalOcean Droplet & Volume Backups?</>}
      text="Find out more and get started with our DigitalOcean Backup guides"
      tags={['do-snapshot', 'digitalocean']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['do_why', 'do_how', 'do_regions', 'do_storage', 'use_for_client']}/>

  </Layout.Layout>
)

export default ContentPage
