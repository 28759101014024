import DigitalOceanPage from "./digitalocean/_digitaloceanPage";

export default DigitalOceanPage

export const frontmatter = {
  pageMetaData: {
    refKey: "digitalocean",
    title: "Automated DigitalOcean Backup Solution",
    description: "DigitalOcean Hourly Backups - Get more out of your backups than what DigitalOcean allows, cheaper than digitalocean snapshots. Flexible schedules stored where you want.",
    author: "simplebackups.com"
  }
}